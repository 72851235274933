import consumer from "./consumer"

consumer.subscriptions.create("AppearanceChannel", {
    connected() {
      return this.perform("appear");
    },

    // Called when the WebSocket connection is closed.
    disconnected() {
      return this.perform("disappear");
    },

    // Called when the subscription is rejected by the server.
    rejected() {
      return this.perform("disappear");
    },
});
