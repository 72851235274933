/* eslint-disable no-tabs */
/* eslint-disable consistent-return */
function setupCheckItemEventListeners() {
  // Error HTML
  const errHtml = `<div class="global_alert spz-error">
      <div data-controller="alerts"><div class="alert alert-danger alert-dismissible text-center" role="alert" data-alerts-target="errorAlert" style="opacity: 369.597;">Please answer the question.<button class="close" data-action="click->alerts#dismissAlert">x</button></div></div>
      </div>`;

  // Function to enable or disable CTA buttons based on checkbox states
  function handleCheckboxChange(selector, stepClass) {
    const checkedItems = document.querySelectorAll(
      `input[name="${selector}"]:checked`,
    ).length;
    const ctaButton = document.querySelector(`.${stepClass} .spz-step-cta`);

    if (checkedItems > 0) {
      ctaButton.removeAttribute('disabled');
    } else {
      ctaButton.setAttribute('disabled', true);
    }
  }

  // Function to add event listeners for checkboxes and their parent divs
  function addEventListeners(item, selector, stepClass) {
    const childCheckbox = item.querySelector('input[type="checkbox"]');
    const childRadio = item.querySelector('input[type="radio"]');

    // Function to handle checkbox change
    function handleCheckboxChangeEvent() {
      item.classList.toggle('!border-store-darkgray');
      const childLabel = item.querySelector('label');
      childLabel.classList.toggle('font-bold');
      handleCheckboxChange(selector, stepClass);

      // If 'none_other' is checked, uncheck other checkboxes
      if (childCheckbox.id === 'none_other' && childCheckbox.checked) {
        document
          .querySelectorAll(`input[name="${selector}"]:checked`)
          .forEach((cb) => {
            if (cb.id !== 'none_other') {
              const noneCheckBox = cb;
              const cbChildLabel = noneCheckBox
                .closest('.spz-check-item')
                .querySelector('label');
              cbChildLabel.classList.remove('font-bold');
              noneCheckBox.checked = false;
              noneCheckBox
                .closest('.spz-check-item')
                .classList.remove('!border-store-darkgray');
            }
          });
      } else if (childCheckbox.checked) {
        // If any other checkbox is checked, uncheck 'none_other'
        const noneOtherCheckbox = document.getElementById('none_other');
        if (noneOtherCheckbox) {
          const noneLabel = noneOtherCheckbox
            .closest('.spz-check-item')
            .querySelector('label');
          noneLabel.classList.remove('font-bold');
          noneOtherCheckbox.checked = false;
          noneOtherCheckbox
            .closest('.spz-check-item')
            .classList.remove('!border-store-darkgray');
        }
      }
    }

    // Function to handle radio button change
    function handleRadioChangeEvent() {
      // Remove the border class from other radio buttons
      document.querySelectorAll(`input[name="${selector}"]`).forEach((rb) => {
        const radioChildLabel = rb
          .closest('.spz-radio-item')
          .querySelector('label');
        radioChildLabel.classList.remove('font-bold');
        rb.closest('.spz-radio-item').classList.remove(
          '!border-store-darkgray',
        );
      });

      // Add the border class to the checked radio button
      item.classList.add('!border-store-darkgray');
      const cRadioChildLabel = item.querySelector('label');
      cRadioChildLabel.classList.toggle('font-bold');
    }

    // Event listener for the parent div
    item.addEventListener('click', (event) => {
      if (
        (event.target.tagName.toLowerCase() === 'input' &&
          (event.target.type === 'checkbox' ||
            event.target.type === 'radio')) ||
        event.target.tagName.toLowerCase() === 'label'
      ) {
        return;
      }

      if (childCheckbox) {
        childCheckbox.checked = !childCheckbox.checked;
        handleCheckboxChangeEvent();
      }

      if (childRadio) {
        childRadio.checked = true;
        handleRadioChangeEvent();
        const changeEvent = new Event('change', { bubbles: true });
        childRadio.dispatchEvent(changeEvent);
      }
    });

    // Event listener for the checkbox
    if (childCheckbox) {
      childCheckbox.addEventListener('change', handleCheckboxChangeEvent);
    }

    // Event listener for the radio button
    if (childRadio) {
      childRadio.addEventListener('change', handleRadioChangeEvent);
    }
  }

  // Add event listeners to each .spz-check-item
  document.querySelectorAll('.spz-check-item').forEach((item) => {
    if (item.querySelector('input[name="looking_to_improve"]')) {
      addEventListeners(item, 'looking_to_improve', 'step1');
    }

    if (item.querySelector('input[name="medication_tried"]')) {
      addEventListeners(item, 'medication_tried', 'step3');
    }
  });

  // Add event listeners to each .spz-radio-item
  document.querySelectorAll('.spz-radio-item').forEach((item) => {
    if (item.querySelector('input[name="rugiet_interested"]')) {
      addEventListeners(item, 'rugiet_interested', 'step2');
    }
  });

  // Function to handle CTA button clicks
  function setupCtaButtonClick(selector, stepClass, nextStepClass) {
    document
      .querySelector(`.${stepClass} .spz-step-cta`)
      .addEventListener('click', () => {
        if (stepClass === 'step3') {
          document.querySelector('.questionnaire').style = 'display:block';
          document.querySelector('.spz-steps-wrapper').remove();
          return;
        }
        if (document.querySelector('.spz-error')) {
          document.querySelector('.spz-error').remove();
        }
        if (
          document.querySelectorAll(`input[name="${selector}"]:checked`)
            .length > 0
        ) {
          document
            .querySelector('.spz-form-step.active')
            .classList.remove('active');
          document
            .querySelector(`.spz-form-step.${nextStepClass}`)
            .classList.add('active');
        } else {
          document
            .querySelector('.main-container')
            .insertAdjacentHTML('afterbegin', errHtml);
        }
      });
  }

  // Setup CTA button click events
  setupCtaButtonClick('looking_to_improve', 'step1', 'step2');
  setupCtaButtonClick('rugiet_interested', 'step2', 'step3');
  setupCtaButtonClick('medication_tried', 'step3', 'step4');
}

function loadSpiralyzeSignup() {
  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
      }
      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
    });
  }

  if (window.location.pathname.indexOf('/users/sign_up') > -1) {
    waitForElm('body').then((bodyEle) => {
      if (!bodyEle.classList.contains('spz-signup-5003')) {
        bodyEle.classList.add('spz-signup-5003');
      }
      const is_opera =
        !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
      const is_Edge = navigator.userAgent.indexOf('Edge') > -1;
      const is_chrome = !!window.chrome && !is_opera && !is_Edge;
      const is_firefox = typeof window.InstallTrigger !== 'undefined';
      if (is_chrome) {
        document.body.classList.add('chrome');
      } else if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        document.body.classList.add('safari');
      } else if (is_firefox) {
        document.body.classList.add('firefox');
      }
    });
    waitForElm('.main-container form').then(() => {
      document
        .querySelector('.main-container form')
        .classList.add('spz-signup-form');
      document.querySelector('#q-questions').classList.add('form-step');
      document.querySelector('.questionnaire').style = 'display:none';
      const stepHtml = `<div class="spz-steps-wrapper">
			  <div class="spz-step-list">
				  <div class="spz-form-step step1 active">
					  <div class="text-h2 mb-q-24 mt-q-24">What are you looking to improve?</div>
					  <div class="spz-checklist">
						  <div class="spz-check-item">
							  <input type="checkbox" name="looking_to_improve" id="better-erections" value="Better erections">
							  <label for="better-erections">Better erections</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="looking_to_improve" id="more_arousal" value="More arousal">
							  <label for="more_arousal">More arousal</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="looking_to_improve" id="more_confidence" value="More confidence">
							  <label for="more_confidence">More confidence</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="looking_to_improve" id="faster_rebound_time" value="Faster rebound time">
							  <label for="faster_rebound_time">Faster rebound time</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="looking_to_improve" id="last_longer" value="Last longer">
							  <label for="last_longer">Last longer</label>
						  </div>
					  </div>
					  <div class="spz-cta-wrapper">
						  <button class="spz-step-cta" type="button" disabled="true">Next</button>
					  </div>
					  <div class="spz-license-notes">
						  <p>All Rugiet Health medical evaluations and prescriptions are provided by US-Licensed physicians and pharmacists.</p>
					  </div>
				  </div>
				  <div class="spz-form-step step2">
					  <div class="text-h2 mb-q-24 mt-q-24">Why are you interested in trying Rugiet Ready?</div>
					  <div class="spz-radiolist">
						  <div class="spz-radio-item">
							  <input type="radio" class="!w-[14px] !h-[14px] tablet:!w-[18px] tablet:!h-[18px] checked:!w-[14px] checked:!h-[14px] tablet:checked:!w-[18px] tablet:checked:!h-[18px] border-[1px] !border-black-charcoal checked:border-[3px] bg-white !ring-offset-0 !ring-0 group-hover:border-[3px] text-black p-0" name="rugiet_interested" id="interested_1" value="Traditional medications like Viagra and Cialis didn’t work for me.">
							  <label for="interested_1"><span>Traditional medications like Viagra and Cialis didn’t <br class="hidden desktop:block"/>work for me.</span></label>
						  </div>
						  <div class="spz-radio-item">
							  <input type="radio" class="!w-[14px] !h-[14px] tablet:!w-[18px] tablet:!h-[18px] checked:!w-[14px] checked:!h-[14px] tablet:checked:!w-[18px] tablet:checked:!h-[18px] border-[1px] !border-black-charcoal checked:border-[3px] bg-white !ring-offset-0 !ring-0 group-hover:border-[3px] text-black p-0" name="rugiet_interested" id="interested_2" value="I like that Rugiet Ready works faster than pills or chews.">
							  <label for="interested_2"><span>I like that Rugiet Ready works faster than pills or chews.</span></label>
						  </div>
						  <div class="spz-radio-item">
							  <input type="radio" class="!w-[14px] !h-[14px] tablet:!w-[18px] tablet:!h-[18px] checked:!w-[14px] checked:!h-[14px] tablet:checked:!w-[18px] tablet:checked:!h-[18px] border-[1px] !border-black-charcoal checked:border-[3px] bg-white !ring-offset-0 !ring-0 group-hover:border-[3px] text-black p-0" name="rugiet_interested" id="interested_3" value="I prefer medications made in the USA with a real US-based customer service team.">
							  <label for="interested_3"><span>I prefer medications made in the USA with a real <br class="hidden desktop:block"/>US-based customer service team.</span></label>
						  </div>
					  </div>
					  <div class="spz-cta-wrapper">
						  <button class="spz-step-cta spz-next desktop:!w-[225px]" type="button" disabled="true">Next</button>
						  <button class="spz-step-back desktop:!w-[225px]" type="button">Go Back</button>
					  </div>
					  <div class="spz-license-notes">
						  <p>All Rugiet Health medical evaluations and prescriptions are provided by US-Licensed physicians and pharmacists.</p>
					  </div>
				  </div>
				  <div class="spz-form-step step3">
					  <div class="text-h2 mb-q-24 mt-q-24">What ED medications have you tried?</div>
					  <div class="spz-radiolist">
						  <div class="spz-check-item">
							  <input type="checkbox" name="medication_tried" id="viagra" value="Viagra">
							  <label for="viagra">Viagra</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="medication_tried" id="cialis" value="Cialis">
							  <label for="cialis">Cialis</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="medication_tried" id="stendra" value="Stendra">
							  <label for="stendra">Stendra</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="medication_tried" id="levitra" value="Levitra">
							  <label for="levitra">Levitra</label>
						  </div>
						  <div class="spz-check-item">
							  <input type="checkbox" name="medication_tried" id="none_other" value="None/Other">
							  <label for="none_other">None/Other</label>
						  </div>
					  </div>
					  <div class="spz-cta-wrapper">
						  <button class="spz-step-cta spz-next store-button desktop:!w-[225px]" type="button" disabled="true">Next</button>
						  <button class="spz-step-back desktop:!w-[225px]" type="button">Go Back</button>
					  </div>
					  <div class="spz-license-notes">
						  <p>All Rugiet Health medical evaluations and prescriptions are provided by US-Licensed physicians and pharmacists.</p>
					  </div>
				  </div>
			  </div>
		  </div>`;

      if (document.querySelectorAll('.spz-steps-wrapper').length === 0) {
        document
          .querySelector('.form-step')
          .insertAdjacentHTML('beforebegin', stepHtml);
      }
      let bi = 0;
      const btnInterval = setInterval(() => {
        bi += 1;
        if (bi > 25 || !document.querySelector('.spz-steps-wrapper')) {
          return clearInterval(btnInterval);
        }
        if (
          document.querySelectorAll('input[name="looking_to_improve"]:checked')
            .length > 0
        ) {
          document
            .querySelector('.step1 .spz-step-cta')
            .removeAttribute('disabled');
        } else {
          document
            .querySelector('.step1 .spz-step-cta')
            .setAttribute('disabled', true);
        }

        if (
          document.querySelectorAll('input[name="rugiet_interested"]:checked')
            .length > 0
        ) {
          document
            .querySelector('.step2 .spz-step-cta')
            .removeAttribute('disabled');
        } else {
          document
            .querySelector('.step2 .spz-step-cta')
            .setAttribute('disabled', true);
        }

        if (
          document.querySelectorAll('input[name="medication_tried"]:checked')
            .length > 0
        ) {
          document
            .querySelector('.step3 .spz-step-cta')
            .removeAttribute('disabled');
        } else {
          document
            .querySelector('.step3 .spz-step-cta')
            .setAttribute('disabled', true);
        }
      }, 400);

      document
        .querySelectorAll('input[name="looking_to_improve"]')
        .forEach((elm) => {
          elm.addEventListener('change', () => {
            if (
              document.querySelectorAll(
                'input[name="looking_to_improve"]:checked',
              ).length > 0
            ) {
              document
                .querySelector('.step1 .spz-step-cta')
                .removeAttribute('disabled');
            } else {
              document
                .querySelector('.step1 .spz-step-cta')
                .setAttribute('disabled', true);
            }
          });
        });
      document
        .querySelectorAll('input[name="rugiet_interested"]')
        .forEach((elm) => {
          elm.addEventListener('change', () => {
            if (
              document.querySelectorAll(
                'input[name="rugiet_interested"]:checked',
              ).length > 0
            ) {
              document
                .querySelector('.step2 .spz-step-cta')
                .removeAttribute('disabled');
            } else {
              document
                .querySelector('.step2 .spz-step-cta')
                .setAttribute('disabled', true);
            }
          });
        });
      document
        .querySelectorAll('input[name="medication_tried"]')
        .forEach((elm) => {
          elm.addEventListener('change', () => {
            if (
              document.querySelectorAll(
                'input[name="medication_tried"]:checked',
              ).length > 0
            ) {
              document
                .querySelector('.step3 .spz-step-cta')
                .removeAttribute('disabled');
            } else {
              document
                .querySelector('.step3 .spz-step-cta')
                .setAttribute('disabled', true);
            }
          });
        });

      document
        .querySelector('.step3 .spz-step-back')
        .addEventListener('click', () => {
          if (document.querySelector('.spz-error')) {
            document.querySelector('.spz-error').remove();
          }
          document
            .querySelector('.spz-form-step.active')
            .classList.remove('active');
          document
            .querySelector('.spz-form-step.step2')
            .classList.add('active');
        });
      document
        .querySelector('.step2 .spz-step-back')
        .addEventListener('click', () => {
          if (document.querySelector('.spz-error')) {
            document.querySelector('.spz-error').remove();
          }
          document
            .querySelector('.spz-form-step.active')
            .classList.remove('active');
          document
            .querySelector('.spz-form-step.step1')
            .classList.add('active');
        });
    });
  }

  waitForElm('.spz-check-item').then(setupCheckItemEventListeners);
}

$(document).on('turbolinks:load', () => {
  if (!window.location.pathname.includes('/users/sign_up')) return;

  loadSpiralyzeSignup();
});
